import { Clearance, FlightPlan, FlightPlanStatus } from "@vatsim-vnas/js-libs/models/vnas/flight-data";

export default class TdlsFlightPlan {
  aircraftId: string;

  tdlsFacility?: string;

  cid: string;

  receivedTime: Date;

  status: FlightPlanStatus;

  assignedBeaconCode?: number;

  aircraftType: string;

  faaEquipmentSuffix: string;

  altitude: string;

  departure: string;

  destination: string;

  route: string;

  remarks: string;

  estimatedDepartureTime: number;

  hoursEnroute: number;

  minutesEnroute: number;

  tdlsDumped: boolean;

  clearance?: Clearance;

  constructor(flightPlan: FlightPlan) {
    this.aircraftId = flightPlan.aircraftId;
    this.cid = flightPlan.cid;
    this.receivedTime = flightPlan.receivedTime;
    this.status = flightPlan.status;
    this.assignedBeaconCode = flightPlan.assignedBeaconCode;
    this.aircraftType = flightPlan.aircraftType;
    this.faaEquipmentSuffix = flightPlan.faaEquipmentSuffix;
    this.altitude = flightPlan.altitude;
    this.departure = flightPlan.departure;
    this.destination = flightPlan.destination;
    this.route = flightPlan.route;
    this.remarks = flightPlan.remarks;
    this.estimatedDepartureTime = flightPlan.estimatedDepartureTime;
    this.hoursEnroute = flightPlan.hoursEnroute;
    this.minutesEnroute = flightPlan.minutesEnroute;
    this.tdlsDumped = flightPlan.tdlsDumped;
    this.clearance = flightPlan.clearance;
  }
}
