import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { AircraftList } from "src/enums";
import { useTdls } from "src/hooks";
import {
  facilityIdSelector,
  highlightedAircraftIdSelector,
  notificationSoundIsEnabledSelector,
  selectedAircraftListSelector,
  setHighlightedAircraftId,
  useAppSelector,
} from "src/redux";
import * as S from "src/styles/tdls/dcl";
import AircraftRow from "./AircraftRow";

const soundAudio = new Audio(`/sounds/notification.mp3`);

function UpdatedHighlightedAircraft(
  highlightedAircraftId: string | undefined,
  listAircraftIds: string[],
  dispatch: Dispatch,
) {
  if (!listAircraftIds.length) {
    if (highlightedAircraftId) {
      dispatch(setHighlightedAircraftId(undefined));
    }

    return;
  }

  if (!highlightedAircraftId || !listAircraftIds.includes(highlightedAircraftId)) {
    dispatch(setHighlightedAircraftId(listAircraftIds[0]));
  }
}

export function DclAircraftList() {
  const LIST = AircraftList.Dcl;

  const selectedAircraftList = useAppSelector(selectedAircraftListSelector);
  const highlightedAircraftId = useAppSelector(highlightedAircraftIdSelector);
  const facilityId = useAppSelector(facilityIdSelector);
  const soundEnabled = useAppSelector(notificationSoundIsEnabledSelector);
  const dispatch = useDispatch();
  const dclCountRef = useRef<number>();
  const { getFlightPlansInList } = useTdls();
  const flightPlans = getFlightPlansInList(AircraftList.Dcl);

  useEffect(() => {
    dclCountRef.current = undefined;
  }, [facilityId]);

  useEffect(() => {
    if (selectedAircraftList !== LIST) {
      return;
    }

    UpdatedHighlightedAircraft(
      highlightedAircraftId,
      flightPlans.map((f) => f.aircraftId),
      dispatch,
    );
  }, [selectedAircraftList, highlightedAircraftId, flightPlans.length]);

  useEffect(() => {
    const dclCount = flightPlans.length;
    if (soundEnabled && dclCountRef.current !== undefined && dclCount > dclCountRef.current) {
      soundAudio.play();
    }
    dclCountRef.current = dclCount;
    if (dclCount > 0) {
      document.title = `(${dclCount}) vTDLS | ${facilityId}`;
    } else {
      document.title = `vTDLS | ${facilityId}`;
    }
  }, [flightPlans]);

  return (
    <S.AircraftList>
      {flightPlans.map((f) => (
        <AircraftRow key={f.aircraftId} aircraftId={f.aircraftId} />
      ))}
    </S.AircraftList>
  );
}

export function PdcAircraftList() {
  const LIST = AircraftList.Pdc;

  const selectedAircraftList = useAppSelector(selectedAircraftListSelector);
  const highlightedAircraftId = useAppSelector(highlightedAircraftIdSelector);
  const dispatch = useDispatch();
  const { getFlightPlansInList } = useTdls();
  const flightPlans = getFlightPlansInList(AircraftList.Pdc);

  useEffect(() => {
    if (selectedAircraftList !== LIST) {
      return;
    }

    UpdatedHighlightedAircraft(
      highlightedAircraftId,
      flightPlans.map((f) => f.aircraftId),
      dispatch,
    );
  }, [selectedAircraftList, highlightedAircraftId, flightPlans.length]);

  return (
    <S.ClearedAircraftList>
      {flightPlans.map((f) => (
        <AircraftRow key={f.aircraftId} aircraftId={f.aircraftId} />
      ))}
    </S.ClearedAircraftList>
  );
}

export function CpdlcAircraftList() {
  return <S.ClearedAircraftList />;
}
